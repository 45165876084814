import CalculateActiveSearchFilters from './calculateActiveSearchFilters'
import SearchFormAction from './searchFormAction'
import Helpers from './helpers'
import CustomEvents from './customEvents'
import NouisliderManager from "./manager/nouislider_manager";
import { Modal } from "bootstrap";

const custom_events = new CustomEvents()
const searchFormInputChangedEvent = custom_events.searchFormInputChangedEvent()

const events = new CustomEvents()
const filtersChangedEvent = events.filtersChangedEvent()
const helpers = new Helpers()

const search_form_action = new SearchFormAction()

function formatAlgoliaResult (suggestion, suggestionType) {
  if (suggestionType == 'model') {
    var modelName = (suggestion._highlightResult.sanitized_name.value.length > 28) ? suggestion._highlightResult.sanitized_name.value.slice(0, 25) + '...' : suggestion._highlightResult.sanitized_name.value
    return '<span>' + modelName[0].toUpperCase() + modelName.slice(1, modelName.length) + '</span><span>' + suggestion._highlightResult.brand_name.value + '</span>'
  } else {
    return '<span>' + suggestion._highlightResult.name.value + '</span>'
  }
}

function handleOrderBySelection () {
  let selectElement = document.querySelector('#custom_order_by')
  if (!selectElement) {
    return
  }
  let jsOrderByInput = document.querySelector('#js-search_order_by')
  selectElement.addEventListener('change', function () {

    jsOrderByInput.value = selectElement.value
    submitFilterSearch('#home-search')

  })

}

function seatsText(infValue, supValue) {
    let tooltip_text = "";
    if (supValue == 7) {
        tooltip_text = ' et plus'
    }
    let finalText = infValue + ' - ' + supValue + tooltip_text
    return finalText
}

function ageText(value, supValue) {
    let tooltip_text = " ans";
    if (value == 35) {
        tooltip_text += ' et plus'
    }
    let finalText = value + tooltip_text
    return finalText
}

function licenceText(value, supValue) {
    let tooltip_text = " ans";
    if (value == 10) {
        tooltip_text += ' et plus'
    }
    let finalText = value + tooltip_text
    return finalText
}

function defaultTooltipText(separator = ' - ') {
    return function(infValue, supValue) {
        return supValue == null ? infValue : infValue + separator + supValue
    }
}

function sliderDivConfiguration () {
  return {
    'dailyPriceSliderDiv': document.querySelector('#js-search_daily_price_slider'),
    'yearSliderDiv': document.querySelector('#js-search_year_slider'),
    'minAgeSliderDiv': document.querySelector('#js-search_driver_min_age_slider'),
    'licenceSliderDiv': document.querySelector('#js-search_driver_min_licence_slider'),
    'seatsSliderDiv': document.querySelector('#js-search_seats_slider')
  }
}

let yearSliderManager, seatsSliderManager, dailyPriceSliderManager, licenceSliderManager, minAgeSliderManager;

function createNouiSliders () {
  const sliderDivs = sliderDivConfiguration()
  const dailyPriceSliderDiv = sliderDivs['dailyPriceSliderDiv']
  if (!dailyPriceSliderDiv) {
    return
  }

  document.querySelectorAll('.noUi-base').forEach(function (element) {
    element.remove()
  })
  Object.entries(sliderDivs).forEach(([key, el]) => {el.noUiSlider ? el.noUiSlider.destroy() : null})

  dailyPriceSliderManager = new NouisliderManager(dailyPriceSliderDiv, helpers.formatTooltipPrice)

  const infDailyPriceInput = document.querySelector('#js-search_inf_daily_price')
  const supDailyPriceInput = document.querySelector('#js-search_sup_daily_price')
  dailyPriceSliderManager.initialize(dailyPriceSliderDiv.dataset, [infDailyPriceInput, supDailyPriceInput], defaultTooltipText('€ - '))

  const yearSliderDiv = sliderDivs['yearSliderDiv']
  yearSliderManager = new NouisliderManager(yearSliderDiv, helpers.formatDefaultTooltipMultipleValues)

  var infYearInput = document.querySelector('#js-search_min_year')
  var supYearInput = document.querySelector('#js-search_max_year')
  yearSliderManager.initialize(yearSliderDiv.dataset, [infYearInput, supYearInput], defaultTooltipText())


  const infSeatsInput = document.querySelector('#js-search_min_seats')
  const supSeatsInput = document.querySelector('#js-search_max_seats')
  const seatsSliderDiv = sliderDivs['seatsSliderDiv']
  seatsSliderManager = new NouisliderManager(seatsSliderDiv, helpers.formatDefaultTooltipMultipleValues)
  seatsSliderManager.initialize(seatsSliderDiv.dataset, [infSeatsInput, supSeatsInput], seatsText)

  const licenceSliderDiv = sliderDivs['licenceSliderDiv']
  licenceSliderManager = new NouisliderManager(licenceSliderDiv, helpers.formatDefaultTooltipMultipleValues)
  let licenceInput = document.querySelector('#js-search_driver_min_licence')
  licenceSliderManager.initialize(licenceSliderDiv.dataset, [licenceInput], licenceText)

  const minAgeSliderDiv = sliderDivs['minAgeSliderDiv']
  minAgeSliderManager = new NouisliderManager(minAgeSliderDiv, helpers.formatDefaultTooltipMultipleValues)
  const minAgeInput = document.querySelector('#js-search_driver_min_age')
  minAgeSliderManager.initialize(minAgeSliderDiv.dataset, [minAgeInput], ageText)

  // Prevents modal from closing on click
  const form = document.querySelector("#js-filter_search_panel");
  const dropdowns = form.querySelectorAll(".dropdown-menu");
  dropdowns.forEach(function (dropdown) {
      dropdown.addEventListener("click", (e)=>{
          e.stopPropagation();
      })
  })

  // Remove prompt on click on select
  const filterSelect = document.querySelector("#custom_order_by");
  if (filterSelect) {
      filterSelect.addEventListener('focus', ()=>{
          Array.from(filterSelect.children).filter(child => child.getAttribute("value") === "")
              .forEach(child => child.remove());
      });
  }

  // Management Of Location Input
  var locationInput = document.querySelector('#js-search_location_autocomplete')

  locationInput.addEventListener('change', function () {
      document.querySelector('#js-search_radius').value = ''
      if(!helpers.is_mobile()) {
          submitFilterSearch('#home-search')
      }
  })
}

function ensureBodyStylesConsistency (inputElements, changedElement) {

  let all_element = inputElements.find((element) => element.value === 'tout')
  const other_elements = inputElements.filter((element) => element.value !== 'tout')
  if (changedElement.value === 'tout' && changedElement.checked) {
    for (let other_element of other_elements) {
      other_element.checked = ''
    }
  }
  if (changedElement.value !== 'tout' && changedElement.checked) {
    all_element.checked = ''
  }
}

function submitFilterSearch (form_id) {
  const form = document.querySelector(form_id)
  const message = 'Recherche des voitures en cours...'


  search_form_action.launchLoader(message)



  fetch(form.getAttribute('action'), {
    method: 'POST',
    body: new FormData(form) // event.target is the form
  }).then((response) => {

    const modal = Modal.getInstance(document.getElementById('searchModal'))
    if(modal) {
      modal.hide();
    }
    search_form_action.closeLoader()
    if (response.redirected) {
      window.location.href = response.url;
    }
  })

}

function calculateActiveSearchFilters () {
  const div = document.querySelector('#nb-active-search-filters')
  if (!div) {
    return
  }

  const div2 = document.querySelector('#js-filters-nb-button')
  let service = new CalculateActiveSearchFilters()
  let nb = service.process()
  div.innerHTML = nb
  div2.innerHTML = nb
  if (nb > 0) {
    div2.parentElement.classList.add("text-bold")
  }
}

function calculateActiveDesktopFilters () {
    calculateActiveFeaturesFilters();
    calculateActiveOptionsFilters();
    calculateActivePriceFilters();
}

function calculateActiveFeaturesFilters () {
    const parentDiv = document.querySelector('#filtersDropdownFeatures')
    if (!parentDiv) {
        return
    }
    const div = parentDiv.firstChild

    let service = new CalculateActiveSearchFilters()
    let nb = service.processFeatures()
    if (nb > 0) {
        if (nb > 1) {
            div.innerHTML = "Caractéristiques (" + nb + ")"
        } else {
            div.innerHTML = "Caractéristique (" + nb + ")"
        }
        div.className = "text-bold";
    }else{
      div.classList.remove("text-bold");
      div.innerHTML = "Caractéristique"
    }
}

function calculateActiveOptionsFilters () {
    const parentDiv = document.querySelector('#filtersDropdownOptions')
    if (!parentDiv) {
        return
    }
    const div = parentDiv.firstChild

    let service = new CalculateActiveSearchFilters()
    let nb = service.processOptions()
    if (nb > 0) {
        if (nb > 1) {
            div.innerHTML = "Options (" + nb + ")"
        } else {
            div.innerHTML = "Option (" + nb + ")"
        }
        div.className = "text-bold";
    }else{
      div.classList.remove("text-bold");
      div.innerHTML = "Option"
    }
}

function calculateActivePriceFilters () {
    const parentDiv = document.querySelector('#filtersDropdownPrice')
    if (!parentDiv) {
        return
    }
    const div = parentDiv.firstChild

    let service = new CalculateActiveSearchFilters()
    let nb = service.processPrice()
    if (nb > 0) {
        div.innerHTML = "Prix par jour (" + nb + ")"
        div.className = "text-bold";
    }
}

function submitFilterSearchAndGetCount (form_id) {
  var form = document.querySelector(form_id)
  let formData = new FormData(form)
  var formDataAsString = new URLSearchParams(formData).toString()
  var request = new XMLHttpRequest()

  request.onreadystatechange = function () {
    if (request.readyState === 4) {
      var response = JSON.parse(request.response)
      let cars_count = 0
      if (response.cars_count) {
        cars_count = response.cars_count
      }
      let new_value = 'Voir les ' + cars_count + ' résultats'
      updateSubmitSearchFormValue(new_value)
    }
  }

  request.open('GET', "/cars_results_count?" + formDataAsString)
  request.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
  request.send(formData)

}

function clickOnFilter (el) {
  if(el && el.name === 'body_styles[]'){
    const inputElements = Array.from(document.getElementsByName('body_styles[]')).filter((element) => element.type === 'checkbox')
    ensureBodyStylesConsistency(inputElements, el)
  }
  //$(":checkbox[id='"+name+"']").attr("checked", $(el).is(":checked"));
  document.dispatchEvent(filtersChangedEvent)
}

function changeSelect (el) {
  let name = el.name
  document.querySelector('select[name=\'' + name + '\']').value = el.value
  document.dispatchEvent(filtersChangedEvent)
}

function resetSearchForm () {
  const search_checkboxes = document.querySelectorAll('.filter-search-checkbox')
  search_checkboxes.forEach(function (checkbox) {
    checkbox.checked = false
  })
  document.querySelector('#force_brand_model').value = ''
  document.querySelector('.filter-search-brand').selectedIndex = -1

  yearSliderManager.reset()
  seatsSliderManager.reset()
  dailyPriceSliderManager.reset()
  licenceSliderManager.reset()
  minAgeSliderManager.reset()

  submitFilterSearch('#js-filter_search_panel form')
}

function resetDropdown (dropdown) {
  const search_checkboxes = dropdown.querySelectorAll('.filter-search-checkbox');
  search_checkboxes.forEach(checkbox => checkbox.checked = false)
}

function resetSliderManagers (sliderManagersArray) {
  sliderManagersArray.forEach(sliderManager => sliderManager.reset())
}

function resetOptions () {
  const dropdown = document.querySelector("#js-filtersDropdownOptions-container");
  const optionsSliderManagers = [licenceSliderManager, minAgeSliderManager]

  resetDropdown(dropdown)
  resetSliderManagers(optionsSliderManagers)

  submitFilterSearch('#js-filter_search_panel form');
}

function resetFeatures () {
    const dropdown = document.querySelector("#js-filtersDropdownFeatures-container");
    const search_checkboxes = dropdown.querySelectorAll('.filter-search-checkbox, .checked');
    search_checkboxes.forEach(function (checkbox) {
      checkbox.checked = false
    })
    document.querySelector('#force_brand_model').value = '';

    yearSliderManager.reset()
    seatsSliderManager.reset()

    submitFilterSearch('#js-filter_search_panel form');
}

function resetPrice () {
    dailyPriceSliderManager.reset()

    submitFilterSearch('#js-filter_search_panel form');
}

function updateSubmitSearchFormValue (value) {
  var submitSearchFormButton = document.querySelector('#submitSearchForm')
  submitSearchFormButton.innerHTML = value
}

function handleHeaderInputs () {
  if (helpers.is_mobile()) {
    document.querySelectorAll('.header-search-inputs input, .header-search-inputs select').forEach(
      (element) => element.addEventListener('change',
        function () {
          if (element.id !== 'js-search_location_display_autocomplete') {
            document.dispatchEvent(searchFormInputChangedEvent)
          }
        }
      )
    )
  }
}

function handleFiltersButtons () {

  let searchCheckboxFilters = document.querySelectorAll('.filter-search-checkbox')
  if (searchCheckboxFilters.length === 0) {
    return
  }
  searchCheckboxFilters.forEach(function (checkbox) {
    checkbox.addEventListener('click', function (el) {
      clickOnFilter(this)
    })
  })

  const select_selector = document.querySelector('.filter-search-select')
  if (select_selector) {
    select_selector.addEventListener('change', function () {
      changeSelect(this)
    })
  }

  const submitSearchFormSelector =
    document.querySelectorAll('.submitSearchForm');
  if (submitSearchFormSelector) {
    submitSearchFormSelector.forEach(function(button) {
      button.addEventListener('click', function (event) {
          event.preventDefault();
          submitFilterSearch('#js-filter_search_panel form');
      })
    })
  }
  const resetSearchFormSelector =
    document.querySelector('#js-reset-search-form')
  if (resetSearchFormSelector) {
    resetSearchFormSelector.addEventListener('click', resetSearchForm)
  }

  const resetOptionsSelector = document.querySelector("#resetOptions");
  if (resetOptionsSelector) {
      resetOptionsSelector.addEventListener('click', resetOptions)
  }

  const resetFeaturesSelector = document.querySelector("#resetFeatures");
  if (resetFeaturesSelector) {
      resetFeaturesSelector.addEventListener('click', resetFeatures)
  }

  const resetPriceSelector = document.querySelector("#resetPrice");
  if (resetPriceSelector) {
      resetPriceSelector.addEventListener('click', resetPrice)
  }

  document.querySelector('#force_brand_model').addEventListener('change', function () {
    clickOnFilter()
  })

}

function handleSearchReinit () {
  var reinitSearch = document.querySelector('#js-reinit_search')
  var isReinitSearchPresent = document.querySelectorAll('#js-reinit_search').length > 0
  if (!isReinitSearchPresent) {
    return
  }
  reinitSearch.addEventListener('click', function () {
    // Context of re-initialisation
    var search_context = reinitSearch.dataset.search_context
    var search_daily_price_slider = document.querySelector('#js-search_daily_price_slider')
    // Min Max Values
    var minDailyPrice = search_daily_price_slider.dataset.min_daily_price || 0
    var maxDailyPrice = search_daily_price_slider.dataset.max_daily_price || 1000
    // Input Reference
    var locationInput = document.querySelector('.js-search_location_autocomplete')
    var locationDisplayInput = document.querySelector('#js-search_location_display_autocomplete')
    var infDailyPriceInput = document.querySelector('#js-search_inf_daily_price')
    var supDailyPriceInput = document.querySelector('#js-search_sup_daily_price')
    var brandModelInput = document.querySelector('#js-search_brand_name')
    // Discussion on search_context
    switch (search_context) {
      case 'no_cars_at_that_price':
        resetSearchForm()
        break
      case 'no_cars_in_the_area' :
        locationInput.value = ''
        locationDisplayInput.value = ''
        break
      case 'no_cars_at_that_price_in_the_area' :
        resetSearchForm()
        break
      case 'no_cars_in_the_area_with_this_brand_model' :
        resetSearchForm()
        break
      case 'no_cars_at_that_price_in_the_area_with_this_brand_model' :
        resetSearchForm()
        break
      default :
        infDailyPriceInput.value = minDailyPrice
        supDailyPriceInput.value = maxDailyPrice
        search_daily_price_slider.noUiSlider.set([minDailyPrice, maxDailyPrice])
        locationInput.value = ''
        locationDisplayInput.value = ''
        brandModelInput.value = ''
        break
    }
    submitFilterSearch('#js-filter_search_panel form')
  })

}
function handleBrandModelChange (event) {
  if (event.type === 'change' && event.isTrigger) {
    submitFilterSearch('#home-search')
  } else {
    if (event.keyCode === 13) {
      submitFilterSearch('#home-search')
    }
  }
}
function initializeHomeFilter () {
  var brandModelInput = document.querySelector('#js-search_brand_model_home')
  var isBrandModelInputPresent = document.querySelectorAll('#js-search_brand_model_home').length > 0
  if (isBrandModelInputPresent) {
    brandModelInput.addEventListener('change', function (event) {
      handleBrandModelChange(event)
    })
    brandModelInput.addEventListener('keydown', function (event) {
      handleBrandModelChange(event)
    })
  }
}

function initializeSearchEngine () {
  if (!helpers.is_mobile()) {
      initializeHomeFilter()
      handleOrderBySelection()
      handleHeaderInputs()
      calculateActiveDesktopFilters()
  }

  handleSearchReinit()
  handleFiltersButtons()
  calculateActiveSearchFilters()
}

document.addEventListener(CustomEvents.EVENTS_KEY['search-form-input-changed'], function (e) {
  if (!document.querySelector('#change_dates_form')) {
    return
  }

  if(helpers.is_mobile()){
    return
  }

  const starts_on = document.querySelector('#search_booking_starts_on').value
  const ends_on = document.querySelector('#search_booking_ends_on').value
  if (!starts_on && !ends_on || starts_on && ends_on) {
    submitFilterSearch('#home-search')
  }
})
document.addEventListener(CustomEvents.EVENTS_KEY['multiselect-closed'], function(){
  if (helpers.is_mobile()) {
    calculateActiveSearchFilters()
  } else {
    submitFilterSearch('#js-filter_search_panel form')
  }
})
document.addEventListener(CustomEvents.EVENTS_KEY['filters-changed'], function () {
  if (helpers.is_mobile()) {
    submitFilterSearchAndGetCount('#js-filter_search_panel form')
    calculateActiveSearchFilters()
  } else {
    calculateActiveDesktopFilters()
  }
})
document.addEventListener(CustomEvents.EVENTS_KEY['multiselect-ready'], function () {
  createNouiSliders()
  initializeSearchEngine()
})