import CustomEvents from '../customEvents'

export default class NouisliderManager {
  constructor (sliderDiv, formatter = null) {
    this.sliderDiv = sliderDiv
    this.formatter = formatter
    const events = new CustomEvents()
    this.filtersChangedEvent = events.filtersChangedEvent()
  }

  reset(){
    if (this.twoValuesSlider) {
      this.noUiSliderInstance.set([this.min, this.max]);
    } else {
      this.noUiSliderInstance.set(this.max);
    }
  }
  initialize (dataset, inputFields, tooltipTextMethod) {

    this.min = parseInt(dataset.defaultMin_value)
    this.max = parseInt(dataset.defaultMax_value)
    let start;
    if ("current_value" in dataset) {
      start = parseInt(dataset.current_value || this.max)
      this.twoValuesSlider = false
    } else {
      start = [
        parseInt(dataset.min_value || this.min),
        parseInt(dataset.max_value || this.max)
      ]
      this.twoValuesSlider = true
    }

    let params = {
      start,
      range: { 'min': [this.min], 'max': [this.max] },
    }
    if (this.twoValuesSlider) {
      params.connect = [false, true, false]
    }
    if (this.formatter != null) {
      params.format = this.formatter()
    }
    noUiSlider.create(this.sliderDiv, params)
    this.noUiSliderInstance = this.sliderDiv.noUiSlider;
    this.update(inputFields, tooltipTextMethod)
  }

  update (inputs, tooltipTextMethod) {
    this.noUiSliderInstance.on('update', (values) => this.updateCallback(values, inputs, tooltipTextMethod))
    this.end(inputs)
  }

  end (inputs) {
    this.noUiSliderInstance.on('end', (values) => this.endCallback(values, inputs))
  }

  updateCallback (values, inputs, tooltipTextMethod) {
    let infValue = this.parseValue(values[0])

    inputs[0].value = infValue
    if (values[1]) {
      inputs[1].value = this.parseValue(values[1])
    }

    // Targets ".js-filter-tooltip"
    const filterTooltip = this.sliderDiv.parentElement.previousSibling
    filterTooltip.innerHTML = tooltipTextMethod(infValue, values[1])
  }

  parseValue (input) {
    return parseInt(input.replace(/[\s|km]/g, ''), 0)
  }

  endCallback (values, inputs) {
    inputs[0].value = this.parseValue(values[0])
    if (this.twoValuesSlider) {
      inputs[1].value = this.parseValue(values[1])
    }
    document.dispatchEvent(this.filtersChangedEvent)

  }
}