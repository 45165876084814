import ReadSmore from 'read-smore'

document.addEventListener('turbo:load', function () {
  const readMores = document.querySelectorAll('.js-read-smore')

  const options = {
    isInline: false,
    moreText: 'Lire plus',
    lessText: 'Lire moins',
    wordsCount: 60
  }

  const RMs = ReadSmore(readMores, options)
  RMs.init()
})
