import BookingService from "./booking_service"
import Helpers from "./helpers"
import { debounce } from './utils'
const price_update = function(){
    const bookingService = new BookingService();
    const ownerPrice = document.querySelector("#js-price-input-text");
    if(!ownerPrice) {
        return
    }
    const updatedTenantPrice = function (ownerPrice) {
        const bookingID = document.querySelector("#js-booking-id").value;
        const ownerPriceValue = ownerPrice.value;
        const formData = {owner_price: ownerPriceValue};
        bookingService.get_tenant_booking_price(formData, bookingID, callback);
    }

    ownerPrice.addEventListener("input", debounce(() => {
        updatedTenantPrice(ownerPrice);
    }));
}
const callback = function (response) {
    const helpers = new Helpers();
    const tenantPrice = document.querySelector("#js-tenant-price-text");
    tenantPrice.textContent = helpers.updated_tenant_price(response.tenant_visible_price);
}
document.addEventListener("turbo:load", function () {
    price_update();
})
document.addEventListener("ready", function () {
    price_update();
})