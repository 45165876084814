// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '@hotwired/turbo-rails'

import 'controllers'
import './common_public'

import noUiSlider from 'nouislider'
import 'nouislider/dist/nouislider.css' // needed to enable webpacker (javascript_pack_tag)

import Cookies from 'js.cookie'
import LazyLoad from 'vanilla-lazyload'
import cookies_eu from 'cookies-eu-banner'
import map from 'underscore/modules/map.js'

import moment from 'moment'

import '../custom/get-url-parameter.js'
import '../custom/filterSearchPanel.js.erb'
import '../custom/registration.js'
import '../custom/read_more.js'
import '../custom/show-price.js'
import '../custom/price_update.js'
import '../custom/int_tel_input.js'

import '../custom/bootstrap'
import '../custom/calendar.js'
import '../custom/extension_modal.js'
import '../custom/conversation_service'
import '../custom/submit_new_booking.js'
import '../custom/new_car_service.js'
import '../custom/first_message_service.js'
import '../custom/password.js'
import '../custom/search_calendar'
import '../custom/car_price_periods'
import '../custom/load_more_service'
import '../custom/show_car_service'
import '../custom/multiselect-dropdown'

import './gems.js.erb'
require('@rails/ujs').start()
Turbo.session.drive = false
require('@rails/activestorage').start()
require('channels')

window.noUiSlider = noUiSlider

window.Cookies = Cookies
window.moment = moment

document.addEventListener('turbo:load', () => {
  const pageLazyLoad = new LazyLoad({})
})

window.initMap = function (...args) {
  const event = document.createEvent('Events')

  event.initEvent('google-maps-callback', true, true)
  event.args = args
  window.dispatchEvent(event)
}

document.addEventListener('turbo:click', function (event) {
  if (event.target.getAttribute('href').charAt(0) === '#') {
    return event.preventDefault()
  }
})
